import React, { useState, useEffect } from "react";
import "./styles.scss";
import ChannelCard from "./ChannelCard";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAlwaysOn,
  selectAdjustedSpendPercent,
  selectSelectedChannels,
  selectIsSetSpend,
  selectSelectedCustomPackage,
  setAdjustedSpendPercent,
  toggleAlwaysOn,
  selectSetSpend,
  fetchMonthlyBudget,
  selectIsBudgetModeFixed,
  selectSelectedLocation
} from "../../../store/campaignForm/CampaignForm";
import { Box, Slider, Tooltip } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { selectChannels } from "../../../store/Channels/ChannelStore";
import { ChannelType } from "../../../models/Channel";
import CustomSwitch from "../../Switch/CustomSwitch";
import { selectCustomPackages } from "../../../store/customPackages/CustomPackagesStore";
import { CustomPackage } from "../../../models/CustomPackage";
import PackageCard from "./PackageCard";
import { ReactComponent as IconMore } from "../../../assets/icons/more-vertical.svg";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import ChannelSidebar from "./ChannelSidebar";
import { AvailableRoutes } from "../../../pages/Routes";
import { useNavigate } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import ChannelTemplateDrawer from "./channelTemplateDrawer/ChannelTemplateDrawer";
import styles from "./styles.module.css";
import CustomTooltip from "../../tooltip/tooltip";
import {
  selectTemplatesForEachChannel
} from "../../../store/CreativeTemplates/CreativeTemplateStore";
import { CreativeTemplate } from "../../../models/CreativeTemplate";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down-orange.svg";

const Channels = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const channels: ChannelType[] = useSelector(selectChannels);
  const packages: CustomPackage[] = useSelector(selectCustomPackages);
  const selectedPackage: CustomPackage | null = useSelector(
    selectSelectedCustomPackage
  );
  const isSetSpend = useSelector(selectIsSetSpend);

  // gets all media from all channels
  const templates: CreativeTemplate[] = useSelector(selectTemplatesForEachChannel);

  const selectedChannels: ChannelType[] = useSelector(selectSelectedChannels);
  const alwaysOn: boolean = useSelector(selectAlwaysOn);
  const adjustedSpendPercent: number = useSelector(selectAdjustedSpendPercent);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const [showSelectedChannels, setShowSelectedChannels] = useState(false);
  const SelectedSpend: number | null = useSelector(selectSetSpend);
  const isMonthlyBudgetFixed = useSelector(selectIsBudgetModeFixed);
  const selectedLocation = useSelector(selectSelectedLocation);

  const [showAlwaysOnTooltip, setShowAlwaysOnTooltip] = useState(false);
  const [showWeeklySpendTooltip, setShowWeeklySpendTooltip] = useState(false);


  const renderPackages = (packages: CustomPackage[]) => {
    const packagesToShow = packages.filter(
      (customPackage) => customPackage.id !== selectedPackage?.id
    );
    return (
      <>
        {packagesToShow.map((customPackage) => (
          <PackageCard
            key={`Package selection card:${customPackage.id}`}
            customPackage={customPackage}
            setShowMobileSidebar={setShowMobileSidebar}
          />
        ))}
      </>
    );
  };

  const isSetSpendInSelectedChannels = () => {
    const ChannelsWithSetSpend = selectedChannels.filter(c => { return c.setSpend != null })
    return ChannelsWithSetSpend.length == 0 ? false : true;
  }

  const renderChannels = (channel: ChannelType) => {
    
    const existsMedia = (templates.some(template => template.channelId === channel.id)) ;

    if (true) {
      
      //Checks if the current channel is actually added
      let isChannelAlreadyAdded = selectedChannels.filter(c => { return c.title === channel.title }).length > 0;

      return (
        <ChannelCard
          key={`channel selection: ${channel.title}`}
          channel={channel}
          isChannelAlreadyAdded={isChannelAlreadyAdded}
          setShowMobileSidebar={setShowMobileSidebar}
          disableMedia={!existsMedia}
        />
      );
    } else {
      return null;
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number" && newValue !== 0) {
      dispatch(setAdjustedSpendPercent(newValue));
    }
  };

  const whiteLabel = useSelector(selectWhiteLabel);
  const muiTheme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          track: { color: "#424242" },
          rail: { color: "#424242" },
          thumb: { backgroundColor: whiteLabel.primaryColor },
        },
      },
    },
  });

  useEffect(() => {
    dispatch(fetchMonthlyBudget(selectedLocation?.id as string));
    dispatch(toggleAlwaysOn());
  }, [selectedLocation]);

  return (
    <div className="channels-container res:mt-7">
{/*      <div className="always-on-container res:!h-auto">
        <div className="always-on-toogle-container">
          <CustomSwitch
            checked={alwaysOn}
            onChange={() => dispatch(toggleAlwaysOn())}
            switchColor={whiteLabel.primaryColor}
          />
          {alwaysOn ? (
            <div className="always-on-title-active res:text-base">Always On</div>
          ) : (
            <div className="always-on-title-inactive res:text-base">Always On</div>
          )}
          <CustomTooltip
            open={showAlwaysOnTooltip}
            onMouseEnter={() => setShowAlwaysOnTooltip(true)}
            onMouseLeave={() => setShowAlwaysOnTooltip(false)}
            title="Campaigns default to an always on subscription. 
            This allows us to optimize your campaign over time for the best results. 
            If you would like to define an end date or black out periods please contact us."
            placement="right-start"
            arrow
          >
            <InfoCircleOutlined
              style={{ color: "#424242", fontSize: "25px", marginLeft: '5px', marginRight: "20px" }}
            />
          </CustomTooltip>
        </div>
         <div className="weekly-spend-container res:mt-4">
          <Box sx={{ width: 188 }} className="weekly-spend-mobile res:flex-wrap res:!w-80">
            <div className="weekly-spend-title res:text-base res:basis-full">{alwaysOn ? 'Weekly spend' : 'Percent spend'}</div>
            <div className={styles['slider-container'] + " res:basis-5/12 res:h-7"}>
              <ThemeProvider theme={muiTheme}>
                <CustomTooltip
                  open={showWeeklySpendTooltip}
                  onMouseEnter={() => setShowWeeklySpendTooltip(true)}
                  onMouseLeave={() => setShowWeeklySpendTooltip(false)}
                  title='Adjust your weekly spend'
                  className="res:hidden"
                  arrow
                >
                  <InfoCircleOutlined
                    style={{ color: "#424242", fontSize: "25px", marginLeft: '5px', marginRight: "20px" }}
                  />
                </CustomTooltip> <Slider
                  value={isSetSpendInSelectedChannels() ? 100 : adjustedSpendPercent}
                  onChange={handleSliderChange}
                  aria-label="Custom marks"
                  step={20}
                  disabled={ isMonthlyBudgetFixed || (isSetSpendInSelectedChannels() && !selectedPackage) ? true : false}
                  className={"slider-spend"}
                />
              </ThemeProvider>
            </div>
            <div className="weekly-spend-value res:basis-full res:!p-0">{isSetSpendInSelectedChannels() ? "100" : adjustedSpendPercent}%</div>
          </Box>
        </div> 
      </div>*/}
      {/*<div className="campaigns-title"></div>*/}
      <div className="channels">
        {renderPackages(packages)}
        {channels.map(renderChannels)}
      </div>

      <div className="mobile-sidebar-button res:max-h-[45%] res:overflow-y-scroll" style={{ left: "0" }}>
        <div className="mobile-button-background res:bg-white res:flex-col">
          { (selectedChannels.length > 0 && showSelectedChannels) && (
            <ChannelSidebar />
          )}

          { selectedChannels.length > 0 && (
            <p 
              className=" text-black text-center text-lg res:flex items-center justify-center res:mb-3"
              onClick={()=> { setShowSelectedChannels(!showSelectedChannels)} }
            >
              {selectedChannels.length} channel(s) added
              { showSelectedChannels && (<ArrowIcon className="res:ml-3 svg-color-primary" style={{ cursor: "pointer" }} />) }
              { !showSelectedChannels && (<ArrowIcon className="res:ml-3 svg-color-primary" style={{ cursor: "pointer", transform: "rotate(180deg)" }} />) }
              
            </p>
          )}

          
          <div
            className={`res:w-full sidebar-dashboard-button pointer-hover ${selectedChannels.length == 0 ? 'ant-btn[disabled]' : null}`}
            onClick={() => {
              if(!selectedChannels.length) return;
              setShowMobileSidebar(false);
              navigate(AvailableRoutes.SCHEDULE);
            }}
          >
            <div className="sidebar-dashboard-button-text">
              SCHEDULE
            </div>
            <IconArrowRight />
          </div>
          <IconMore
            className="mobile-more-icon res:hidden"
            onClick={() => setShowMobileSidebar(!showMobileSidebar)}
          />
          
        </div>
      </div>
      {showMobileSidebar && (
        <div className="mobile-sidebar-container res:!hidden">
          <div className="mobile-sidebar-oppacity" onClick={() => setShowMobileSidebar(false)}></div>
          <div className="mobile-sidebar">
            <ChannelSidebar />
          </div>
        </div>
      )}
      <ChannelTemplateDrawer />
    </div>
  );
};

export default React.memo(Channels);
